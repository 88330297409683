.drag_and_drop {
    border: 2px dashed #777;
    border-radius: 1rem;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    color: #777;
    max-width: 16rem;
    min-width: 16rem;
}

.drag_and_drop__file_icon {
    color: var(--primary-clr);
    font-size: 2rem;
}

.drag_and_drop__text1 {
    font-weight: 700;
    font-size: 0.9rem;
}

.drag_and_drop__text2 {
    font-size: 0.8rem;
}

.drag_and_drop__browse {
    color: var(--primary-clr);
    text-decoration: underline;
    cursor: pointer;
}

.drag_and_drop__browse:hover{
    color: var(--primary-dark-clr);
}


@media screen and (max-width: 600px) {
    .drag_and_drop{
        max-width: 100%;
    }
}