.multiple_drag_drop {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    width: 100%;
}

.multiple_drag_drop__item {
    align-self: last baseline;
    max-width: 16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.multiple_drag_drop__item__title {
    width: 90%;
    text-align: center;
    font-size: 0.9rem;
    color: var(--new-gray-clr2);
    font-weight: 500;
}


@media screen and (max-width: 600px) {
    .multiple_drag_drop {
        justify-content: center;
    }

    .multiple_drag_drop__item {
        max-width: 100%;
    }
}