.upload_button__container{
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: flex-end;
    max-width: 8.5rem;
}

.upload_button{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--text-clr);
    color: var(--text-clr);
    cursor: pointer;
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
    transition: 0.5s;
    max-width: 8.5rem;
}

.upload_button:hover{
    background-color: var(--primary-clr);
    color: white;
}

.upload_button__inactive{
    cursor: default !important;
    background-color: rgb(201, 201, 201) !important;
    color: gray !important;
    border-color: gray !important;
}

.upload_button_title{
    font-weight: 600;
    color: var(--text-clr);
    padding-bottom: 0.5rem;
    font-size: 0.9rem;
}