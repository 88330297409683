:root {
  --primary-clr: #126342;
  --primary-dark-clr: #0c462f;
  --primary-light-clr: #1e9c6a;
  --primary-lighter-clr: #aeffdf;
  --secondary-clr: #DFBB7F;
  --secondary-light-clr: #fce2b5;
  --secondary-dark-clr: #b38e4e;
  --green-clr: #378215;
  --extra-light-green-clr: #3deea752;
  --light-green-clr: #8ad17d;
  --light-green-clr2: rgba(0, 255, 151, 0.23);
  --light-gray-green-clr: #D9E6E0;
  --light-gray-clr: #EDF2F6;
  --new-gray-clr:#ececec;
  --new-gray-clr2: #777;
  --new-gray-clr2-dim:#7777774f;
  --text-clr: #3E3E3E;
  --text-clr2: #808080;
  --text-light-clr: #fff;

  --padding-left-right: 1em;
  --padding-top-bottom: 2em;

  --box-shadow-clr: rgba(34, 34, 34, 0.3);
}


*,
::before,
::after {
  margin: 0;
  box-sizing: border-box;
  line-height: calc(1em + 0.5rem);
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
}

img,
video,
picture,
canvas,
svg {
  display: block;
  max-width: 100%;
  user-select: none;
}

.btn-primary {
  width: 100%;
  height: 3em;
  background-color: var(--primary-clr);
  border-style: none;
  color: white;
  border-radius: 2%;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  font-weight: bold;
}

.btn-primary:hover {
  background-color: var(--primary-dark-clr);
}

.btn-secondary {}

.btn-light {

  width: 200px;
  height: 3em;
  background-color: var(--light-gray-green-clr);
  border-style: none;
  border-radius: 2px;
  color: var(--primary-clr);
  font-weight: bold;
  box-shadow: 2px 2px 10px rgba(10, 10, 10, 0.3);
}

.btn-light:hover {
  background-color: var(--primary-dark-clr);
  color: var(--light-gray-green-clr);
  box-shadow: 2px 2px 10px rgba(10, 10, 10, 0.5);
}

.section-title-green {
  color: var(--primary-clr);
  font-weight: bold;
}

.section-title-gold {}

.react_link {
  text-decoration: none;
}


.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  /* border: 1px solid green; */
}

.header_logo {
  width: 100px;
}



/* Extra small devices (phones) */
@media (max-width: 575.98px) {
  .container {
    max-width: 575.98px;
  }
}

/* Small devices (tablets) */
@media (min-width: 576px) and (max-width: 767.98px) {

  .container {
    max-width: 767.98px;
  }
}

/* Medium devices (desktops) */
@media (min-width: 768px) and (max-width: 991.98px) {

  .container {
    max-width: 991.98px;
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199.98px) {

  .container {
    max-width: 1199.98px;
  }
}

/* Extra large devices (large desktops)
@media (min-width: 1200px) {
  
  
} */