.button_checkbox {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.button_checkbox__item {
    background-color: var(--new-gray-clr);
    border: 1px solid var(--new-gray-clr2-dim);
    padding: 0.5rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    transition: 0.5s;
    font-size: 0.9rem;
}

.button_checkbox__item:hover{
    background-color: var(--light-green-clr);
}

.button_checkbox .item_active{
    background-color: var(--primary-clr);
    color: white;
    font-weight: 500;
}