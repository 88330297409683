.three_buttons{
    display: flex;
    align-items: center;
}

.three_buttons__button{
    padding: 0.5rem 1.5rem;
    font-size: 0.9rem;
    background-color: var(--new-gray-clr);
    cursor: pointer;
    transition: 0.5s;
    text-transform: uppercase;
}

.three_buttons__button:hover{
    background-color: var(--primary-clr);
    color: white;

}

.three_buttons .button_active{
    background-color: var(--primary-clr);
    color: white;
}

.three_buttons .three_buttons__button:first-child{
    border-radius: 2rem 0 0 2rem;
    border-color: var(--new-gray-clr2-dim);
    border-width: 0 2px 0 0;
    border-style: solid;
}

.three_buttons .three_buttons__button:last-child{
    border-radius: 0 2rem 2rem 0;
}

.three_buttons :nth-child(2):not(:last-child){
    border-color: var(--new-gray-clr2-dim);
    border-width: 0 2px 0 0;
    border-style: solid;
}