.processcard_mou{
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: white;
    width: 30%;
    min-width: 22rem;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3);

}
.processcard_mou__top{
    background-color: var(--light-green-clr2);
    border-radius: 0.8rem;
    padding: 2rem 2rem 1.5rem 2rem;
    gap: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 18rem;
}
.processcard_mou__title{
    font-size: 1.75rem;
    font-weight: bold;
    color: var(--primary-clr);
}
.processcard_mou__processes{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}
.processcard_mou__process{
    font-size: 0.9rem;
    font-weight: 300;
    border-radius: 10rem;
    color: var(--primary-clr);
    border: solid 1px var(--primary-clr);
    padding: 0 0.5rem;
}
.processcard_mou__bottom{
    display: flex;
    flex-direction: column;
    padding: 1rem;

}
.processcard_mou__button{
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: var(--primary-clr);
    color: white;
    align-self: flex-end;
    cursor: pointer;
    transition: 0.5s;
}

.processcard_mou__button:hover{
    background-color: var(--primary-dark-clr);
}


.processcard_mou_inactive{
    background-color: rgb(206, 206, 206) !important;
}

.processcard_mou_inactive .processcard_mou__top{
    background-color: rgb(172, 172, 172) !important;
}
.processcard_mou_inactive .processcard_mou__title{
    color: gray;
}
.processcard_mou_inactive .processcard_mou__process{
    color: gray !important;
    border-color: gray !important;
}

.processcard_mou_inactive .processcard_mou__button{

    background-color: rgb(153, 153, 153) !important;
    color: gray !important;
    cursor: default !important;
}