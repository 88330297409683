.general_onboarding{
    background-color: #F5F6FA;
    position: relative;
    overflow-y: auto;
    min-height: 100vh;
    font-family: 'poppins', sans-serif;
}

.sticky-button{
    position: sticky;
    position: -webkit-sticky;
    right: 20px;
    bottom: 20px;
}

.general_onboarding__container{
    display: flex;
    flex-direction: column;
    align-items:center;
    /* background: linear-gradient(to bottom, var(--primary-clr) 50vh, rgb(235, 235, 235) 50vh); */
    background: var(--primary-clr);
}
.general_onboarding__titles{
    margin: 2.5rem;
    text-align: center;
}
.general_onboarding__title{
    font-size: 1.75rem;
    font-weight: 700;
    color: white;
    margin-bottom: 0.5rem;
}

.general_onboarding__subtitle{
    font-size: 1.5rem;
    color:white;
    font-weight: 400;
}

.general_onboarding__form{
    width: 80%;
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-bottom: 4rem;
    box-shadow: 0 0 6px var(--box-shadow-clr);
    height: 100%;
    flex: 1;
}

.general_onboarding__sections{
    display: flex;
    justify-content: space-between;
    gap: 2.5rem;
}

.general_onboarding__section{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
}
.general_onboarding__section_header{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.general_onboarding__section_title{
    font-size: 1.5rem;
    color: var(--primary-clr);
    font-weight: 700;
}
.general_onboarding__section_subtitle{
    font-size: 1rem;
    font-weight: 400;
    color: var(--new-gray-clr2);

}
.general_onboarding__section_inputs{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.general_onboarding__section_sub_inputs{
    display: flex;
    gap: 1rem;
}

.general_onboarding__navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.general_onboarding__dots{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    transition: 0.5s;
}

.general_onboarding__dot{
    cursor: pointer;
    transition: 0.5s;
    color: var(--light-gray-clr);
}

.general_onboarding__dot:hover{
    color: var(--light-green-clr);
}

.general_onboarding .active_dot{
    color: var(--primary-clr);
    transform: scale(1.2);
    transition: 0.5s;
}

@media (max-width: 767.98px) {
    .general_onboarding__title{
        font-size: 1.5rem;
    }

    .general_onboarding__form{
        gap: 2rem;
    }

    .general_onboarding__sections{
        flex-direction: column;
    }

    .general_onboarding__section{
        width: 100%;
    }

    .general_onboarding__section_sub_inputs{
        flex-wrap: wrap;
    }

    .general_onboarding__form{
        width: 95%;
    }

    .general_onboarding__dots{
        display: none;
    }
}