.onboarding_mou{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.onboarding_mou__cards{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
}

.onboarding_mou__title{
    font-size: 2.5rem;
    text-align: center;
    font-weight: 900;
    color: var(--primary-clr);
}

.onboarding_mou__subtitle{
    font-size: 1.5rem;
    text-align: center;
    color: var(--text-clr2);
    margin-bottom: 1rem;

}